

section.faqs-section {
    #accordion div a:not(.collapsed) > span:first-of-type,
    #accordion div a:not(.collapsed) svg {
        color: #242220 !important;
    }
    &.has-contact,
    &.has-testimonials {
        padding-top: 320px;
        @media screen and (max-width: 1600px) {
            & {
                padding-top: 580px !important;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                padding-top: 100px !important;
            }
        }
    }
    
    &.no-contact.has-services {
        padding-top: 150px !important;
    }
    &.has-testimonials.no-contact.no-services {
        padding-top: 270px !important;
        @media screen and (max-width: 1600px) {
            & {
                padding-top: 280px !important;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                padding-top: 100px !important;
            }
        }
    }
        
}