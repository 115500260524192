// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }

.min-vh {
  &-10 { 
    min-height: 10vh !important; 
  }
  &-20 { 
    min-height: 20vh !important; 
  }
  &-30 { 
    min-height: 30vh !important; 
  }
  &-40 { 
    min-height: 40vh !important; 
  }
  &-50 { 
    min-height: 50vh !important; 
  }
  &-60 { 
    min-height: 60vh !important; 
  }
  &-70 { 
    min-height: 70vh !important; 
  }
  &-80 { 
    min-height: 80vh !important; 
  }
  &-90 { 
    min-height: 90vh !important; 
  }
  &-100 { 
    min-height: 100vh !important; 
  }
}
.min-vw {
  &-10 { 
    min-height: 10vw !important; 
  }
  &-20 { 
    min-height: 20vw !important; 
  }
  &-30 { 
    min-height: 30vw !important; 
  }
  &-40 { 
    min-height: 40vw !important; 
  }
  &-50 { 
    min-height: 50vw !important; 
  }
  &-60 { 
    min-height: 60vw !important; 
  }
  &-70 { 
    min-height: 70vw !important; 
  }
  &-80 { 
    min-height: 80vw !important; 
  }
  &-90 { 
    min-height: 90vw !important; 
  }
  &-100 { 
    min-height: 100vw !important; 
  }
}

@include media-breakpoint-up(xl) {
  .min-vh {
    &-xl {
      &-10 { 
        min-height: 10vh !important; 
      }
      &-20 { 
        min-height: 20vh !important; 
      }
      &-30 { 
        min-height: 30vh !important; 
      }
      &-40 { 
        min-height: 40vh !important; 
      }
      &-50 { 
        min-height: 50vh !important; 
      }
      &-60 { 
        min-height: 60vh !important; 
      }
      &-70 { 
        min-height: 70vh !important; 
      }
      &-80 { 
        min-height: 80vh !important; 
      }
      &-90 { 
        min-height: 90vh !important; 
      }
      &-100 { 
        min-height: 100vh !important; 
      }
    }
  }
  .min-vw {
    &-xl {
      &-10 { 
        min-height: 10vw !important; 
      }
      &-20 { 
        min-height: 20vw !important; 
      }
      &-30 { 
        min-height: 30vw !important; 
      }
      &-40 { 
        min-height: 40vw !important; 
      }
      &-50 { 
        min-height: 50vw !important; 
      }
      &-60 { 
        min-height: 60vw !important; 
      }
      &-70 { 
        min-height: 70vw !important; 
      }
      &-80 { 
        min-height: 80vw !important; 
      }
      &-90 { 
        min-height: 90vw !important; 
      }
      &-100 { 
        min-height: 100vw !important; 
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .min-vh {
    &-lg {
      &-10 { 
        min-height: 10vh !important; 
      }
      &-20 { 
        min-height: 20vh !important; 
      }
      &-30 { 
        min-height: 30vh !important; 
      }
      &-40 { 
        min-height: 40vh !important; 
      }
      &-50 { 
        min-height: 50vh !important; 
      }
      &-60 { 
        min-height: 60vh !important; 
      }
      &-70 { 
        min-height: 70vh !important; 
      }
      &-80 { 
        min-height: 80vh !important; 
      }
      &-90 { 
        min-height: 90vh !important; 
      }
      &-100 { 
        min-height: 100vh !important; 
      }
    }
  }
  .min-vw {
    &-lg {
      &-10 { 
        min-height: 10vw !important; 
      }
      &-20 { 
        min-height: 20vw !important; 
      }
      &-30 { 
        min-height: 30vw !important; 
      }
      &-40 { 
        min-height: 40vw !important; 
      }
      &-50 { 
        min-height: 50vw !important; 
      }
      &-60 { 
        min-height: 60vw !important; 
      }
      &-70 { 
        min-height: 70vw !important; 
      }
      &-80 { 
        min-height: 80vw !important; 
      }
      &-90 { 
        min-height: 90vw !important; 
      }
      &-100 { 
        min-height: 100vw !important; 
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .min-vh {
    &-md {
      &-10 { 
        min-height: 10vh !important; 
      }
      &-20 { 
        min-height: 20vh !important; 
      }
      &-30 { 
        min-height: 30vh !important; 
      }
      &-40 { 
        min-height: 40vh !important; 
      }
      &-50 { 
        min-height: 50vh !important; 
      }
      &-60 { 
        min-height: 60vh !important; 
      }
      &-70 { 
        min-height: 70vh !important; 
      }
      &-80 { 
        min-height: 80vh !important; 
      }
      &-90 { 
        min-height: 90vh !important; 
      }
      &-100 { 
        min-height: 100vh !important; 
      }
    }
  }
  .min-vw {
    &-md {
      &-10 { 
        min-height: 10vw !important; 
      }
      &-20 { 
        min-height: 20vw !important; 
      }
      &-30 { 
        min-height: 30vw !important; 
      }
      &-40 { 
        min-height: 40vw !important; 
      }
      &-50 { 
        min-height: 50vw !important; 
      }
      &-60 { 
        min-height: 60vw !important; 
      }
      &-70 { 
        min-height: 70vw !important; 
      }
      &-80 { 
        min-height: 80vw !important; 
      }
      &-90 { 
        min-height: 90vw !important; 
      }
      &-100 { 
        min-height: 100vw !important; 
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .min-vh {
    &-sm {
      &-10 { 
        min-height: 10vh !important; 
      }
      &-20 { 
        min-height: 20vh !important; 
      }
      &-30 { 
        min-height: 30vh !important; 
      }
      &-40 { 
        min-height: 40vh !important; 
      }
      &-50 { 
        min-height: 50vh !important; 
      }
      &-60 { 
        min-height: 60vh !important; 
      }
      &-70 { 
        min-height: 70vh !important; 
      }
      &-80 { 
        min-height: 80vh !important; 
      }
      &-90 { 
        min-height: 90vh !important; 
      }
      &-100 { 
        min-height: 100vh !important; 
      }
    }
  }
  .min-vw {
    &-sm {
      &-10 { 
        min-height: 10vw !important; 
      }
      &-20 { 
        min-height: 20vw !important; 
      }
      &-30 { 
        min-height: 30vw !important; 
      }
      &-40 { 
        min-height: 40vw !important; 
      }
      &-50 { 
        min-height: 50vw !important; 
      }
      &-60 { 
        min-height: 60vw !important; 
      }
      &-70 { 
        min-height: 70vw !important; 
      }
      &-80 { 
        min-height: 80vw !important; 
      }
      &-90 { 
        min-height: 90vw !important; 
      }
      &-100 { 
        min-height: 100vw !important; 
      }
    }
  }
}